// Survey Block
// Import required API’s
import { box, randomBytes } from 'tweetnacl';
import { decodeBase64, decodeUTF8, encodeBase64, encodeUTF8 } from 'tweetnacl-util';
const newNonce = () => randomBytes(box.nonceLength);

const blocksurvey_id = "fb7a2c51-2efe-4247-83cf-0c0de5b06dd2";
const surveyPublicKeyBase64 = "6Ta7L3BGXA0qjdGEIzerf6Iw20WhU36oHiLTQHwyM2Y=";
let questionId = "317cad44-4050-4271-9749-bc7a0de2e322";
const answerObject = {
  "317cad44-4050-4271-9749-bc7a0de2e322": "+2600000000000"
};

//console.log(answerObject)

// Convert Survey's public key from Base64 to Uinit8Array
const surveyPublicKeyUnit8Array = decodeBase64(surveyPublicKeyBase64);
// Generate new pair of keys for each respondent
const respondentKeyPair = box.keyPair();
// Generate a shared key
const sharedKey = box.before(surveyPublicKeyUnit8Array, respondentKeyPair.secretKey);
// Write a function to encrypt JSON
const encryptJsonByTweetnacl = (sharedKey, json, key) => {
          const nonce = newNonce();
	  const messageUint8 = decodeUTF8(JSON.stringify(json));
	  //console.log(box.nonceLength)
	  //console.log(messageUint8 instanceof Uint8Array)
	  //console.log(sharedKey instanceof Uint8Array)
	  //console.log(box(messageUint8, nonce, sharedKey, sharedKey))

	  const encrypted = key
	    ? box(messageUint8, nonce, key, sharedKey)
	    : box.after(messageUint8, nonce, sharedKey);
	
	  const fullMessage = new Uint8Array(nonce.length + encrypted.length);
	  fullMessage.set(nonce);
	  fullMessage.set(encrypted, nonce.length);

	  const base64FullMessage = encodeBase64(fullMessage);
	  return base64FullMessage;
}
// Encrypt the answerObject
const encryptedAnswerObj = encryptJsonByTweetnacl(sharedKey, answerObject);

// payload
const payload = {
	"qid": blocksurvey_id,
	"answers": {
		"d": encryptedAnswerObj,
		"p": encodeBase64(respondentKeyPair.publicKey)
	}
}

//console.log(payload)
const url = 'https://api2.blocksurvey.io/api/blocksurvey/answer';
/*await fetch (url, {
	method: 'POST',
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	},
	body: JSON.stringify(payload)
});
*/

/*
 * ZIZI SMS
 *
 */

let smsURL = 'https://www.api.zizi.money/api/sms/website_registration';
let token = 'eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiYWRtaW4iOnRydWUsImlhdCI6MTUxNjIzOTAyMn0.bQTnz6AuMJvmXXQsVPrxeQNvzDkimo7VNXxHeSBfClLufmCVZRUuyTwJF311JHuh'
var button = document.getElementById('inviteBtn');
var formResponse = document.getElementById('response');

document.getElementById('smsNumber').addEventListener('submit', function(e) {
	e.preventDefault()
}, false)

button.addEventListener('click', async _ => {
  var smsPhone = add260(document.getElementById('smsNumber').value);
  const registerPayload = {
	"phone": smsPhone, 
	"token": token
  }   
  if (validPhoneNumberZM(smsPhone)) {
	  try { 
	    //spinner
             formResponse.innerHTML = "loading... <div class=\"spinner-border spinner-border-sm text-primary\" role=\"status\"><span class=\"sr-only\"></span></div>";
	    const response = await fetch(smsURL, {
	       method: 'POST',
	       headers: {
		 'Accept': 'application/json',
		 'Content-Type': 'application/json'
	       },
               body: JSON.stringify(registerPayload)
	    });
	    
	     // hide tooltip
	     if (response.status != 200) {
	       formResponse.innerHTML = "<span class=\"badge text-bg-danger-subtle rounded-pill mb-3\"><span class=\"h5\"> Ooops that didn't work. Please try again</span></span>";
	     } else {
		formResponse.innerHTML = "<span class=\"badge text-bg-success-subtle rounded-pill mb-3\"><span class=\"h5\"> Nice! Check your phone for more details 🙌🏽</span></span>"
;
	     }

	   } catch(err) {
	      formResponse.innerHTML = '<span class=\"badge text-bg-danger-subtle rounded-pill mb-3\"><span class=\"h5\"> Unable to reserve. Please try again later</span></span>';
	      //console.error(`Error: ${err}`);
	   }
 } else {
   formResponse.innerHTML = "<span class=\"badge text-bg-danger-subtle rounded-pill mb-3\"><span class=\"h5\"> Please use a valid Zambian number</span></span>"
 }
});


/* 
 * Add tooltips
 *
 */
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))


/*
 * Valid phone number
 *
 */
function validPhoneNumberZM(inputtxt) {
     var phoneno = /^(\+260)?(95|96|97|75|76|77)([0-9]{7})$/;
     if(inputtxt.replace(/ /g, "").match(phoneno)) {
       return true;
     }
     else {
       return false;
     }
}

function add260(str) {
  if (str[0] != '+' && str[1] != '2') {
    return '+26' + str;
  } else return str;
}
